.why {
    padding: 10px;
display: flex;
justify-content: center;



}
@media only screen and (min-width: 1000px) and (max-width: 1576px) {
    .why{
font-size: smaller;
    }
  }
@media only screen and (min-width: 1577px) and (max-width: 2000px) {

    .why{
font-size: large;

    }
  }

.whyIcons{
object-fit: contain;
width: 80px;
padding: 15px 0px;
filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.7)) 
drop-shadow(0 0 1px rgba(0, 0, 255, 0.7));

}

.inlB{
    display: inline-block;
    padding: 5px 15px;
    vertical-align: top;
    width: 330px;
}
.tristaPedeset{
    width: 15vw;
    color: #37345e
}
.wcp {
    text-align: center;
    font-size: large;
width: 100%;
  padding-top: 20px;

}
  

.container {
    display: flex;
    justify-content: space-between;
}
.left-column, .right-column, .middle-column {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    padding: 0;
}

li {
    padding: 10px;

}

ul{
    padding-inline-start: 0;
}

@media only screen and (max-width: 1150px) {

    .left-column, .right-column, .middle-column {
    width: 49%;
    }
    
}