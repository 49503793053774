.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 1; /* Start fully opaque */
    transition: opacity 2s ease, visibility 2s ease; 
    visibility: visible; 
}

.loading-overlay.hidden {
    opacity: 0; /* Fade out */
    visibility: hidden; /* Hide completely when faded out */
}

.loading-overlay img {
    width: 300px; /* Adjust size as needed */
}
