.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0; /* Position from the right side */
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 30px;
    
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 0; /* Position the close button all the way to the right */
    font-size: 36px;
    padding: 30px 16px; /* Add padding to the close button */
    border: none;
    color: rgb(204, 200, 200);
    background: none;
    cursor: pointer;
  }
  
  
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  }
  