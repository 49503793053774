/* From Uiverse.io by adeladel522 */ 
.buttonK {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 0.5rem;
    padding-inline: 1.25rem;
    background-color: rgb(60 115 153);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 1.5vw;
    width: 25vw;
  }
  
  .iconK {
    width: 7vw;
    height: 7vw;
    transition: all 0.3s ease-in-out;
  }
  
  .buttonK:hover {
    border-color: #fff9;
  }
  
  .buttonK:hover .icon {
    transform: translate(0px);
  }
  
  .buttonK:hover::before {
    animation: shine 1.2s ease-out ;
  }
  
  .buttonK::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }
  
  @keyframes shine {
    0% {
      left: -100px;
    }
  
    60% {
      left: 100%;
    }
  
    to {
      left: 100%;
    }
  }
  
  @media only screen and (max-width: 1150px) {
  
    .buttonK{
      width: 45vw;
      height: 20vw;
      font-size: 3.5vw;
    }
    .iconK{
      width: 13vw;
      height: 13vw;
    }
  
  }