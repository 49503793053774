.footerB{
    background-color: #2c3a47;
    padding: 2% 10%;
    display: flex;
    align-items: center;
    color: #ffffff;
}
.spanF, 
.spanG {
    padding: 0 6%;
    
}

.im{
filter: drop-shadow(0 0 3px rgba(255, 255, 255, 0.7)) drop-shadow(0 0 1px #ffffff);
 width: 250px;
border-radius: 7px;
}

.spanF p {
    cursor: pointer; /* Makes it clear that these are clickable */
    transition: text-decoration 0.3s; /* Smooth transition for underline */
}

.spanF p:hover {
    text-decoration: underline; /* Adds underline on hover */
}
.ajp{
  
    text-decoration: none;
    color: inherit;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;

}

@media only screen and (max-width: 950px) {

    .nestaniA{
display: none;

    }
  }
  @media only screen and (max-width: 1560px) {

    .nestaniB{
display: none;

    }
  }
  @media only screen and (max-width: 600px) {

    .footerB{
display: block;

    }
  }