.providna{
    background-color: rgb(110 125 133 / 70%);
     padding: 50px;
     border-radius: 0 0 5px 5px;
}

@media only screen and (max-width: 550px) {

    .providna{
        padding: 5vw 1vw;
        background-color: rgb(110 125 133 / 68%);
    }
    .slikaC{
        height: 200px;
    }

}    
.slikaC{
    width: 100%;
    max-height: 600px;
     object-fit: cover;
     opacity: 100%
}

@media only screen and (max-width: 1500px) {

    .slikaC{
        max-height: 500px;
    }

}

.divP{
 
        position: absolute;
        top: 250px; 
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        text-align: center;
        z-index: 1
}

@media only screen and (min-width: 550px) and (max-width: 750px) {

    .divP{
        top: 150px;
        width: 45vw;
    }

}

@media only screen and (max-width: 550px) {

    .divP{
        top: 100px;
        width: 53vw;

    }

}

.textProvidni{
     opacity: 1;
    text-shadow: 3px 4px 5px #000;
    font-size: clamp(17px,1.8vw,1.8vw)
}
