footer span{
    display: inline-flex;
  }
  footer span:nth-child(even){
    overflow: hidden;
    transition:ease-in-out 0.5s;
    letter-spacing: -1em;
  }
  
  footer:hover span:nth-child(even){
  letter-spacing: 0;
  }
  